import { UPDATE_WIZARD_STEP_DATA, CLEAR_WIZARD_DATA, UPDATE_WIZARD_BUSINESS } from "./actionTypes";


export const updateWizardStepData = (stepName, stepData) => {
    return {
        type: UPDATE_WIZARD_STEP_DATA,
        payload: {
            stepData,
            stepName
        }
    }
}

export const updateWizardBusiness = business => {
    return {
        type: UPDATE_WIZARD_BUSINESS,
        payload: {
            business
        }
    }
}

export const resetWizardData = () => {
    return {
        type: CLEAR_WIZARD_DATA
    }
}