import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserAuthenticated } from "../guards/authGuard";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthenticated() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", extras: { ...rest.location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
