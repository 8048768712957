import { UPDATE_WIZARD_STEP_DATA, CLEAR_WIZARD_DATA } from "../actions";
import { UPDATE_WIZARD_BUSINESS } from "../actions/actionTypes";

const initialState = {
    canContinue: false,
    steps: {}
}

export const funnelWizardDataReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case UPDATE_WIZARD_STEP_DATA: {
            newState.canContinue = true;
            newState.steps[action.payload.stepName] = action.payload.stepData;
            break;
        }
        case UPDATE_WIZARD_BUSINESS: {
            newState['business'] = action.payload.business;
            break;
        }
        case CLEAR_WIZARD_DATA: {
            newState = {
                canContinue: false,
                steps: {}
            };
            break;
        }
        default: {
            
        }
    }
    return newState;
}
