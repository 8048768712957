export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';


export const UPDATE_WIZARD_STEP_DATA = 'UPDATE_WIZARD_STEP_DATA';
export const UPDATE_WIZARD_BUSINESS = 'UPDATE_WIZARD_BUSINESS';
export const CLEAR_WIZARD_DATA = 'CLEAR_WIZARD_DATA';


// export const ADD_BUSINESS_DATA = "ADD_BUSINESS_DATA";
// export const ADD_FUNNEL_DATA = "ADD_FUNNEL_DATA";

export const UPDATE_BUSINESS_DATA = "UPDATE_BUSINESS_DATA";
export const UPDATE_FUNNEL_DATA = "UPDATE_FUNNEL_DATA";

export const CLEAR_BUSINESS_DATA = "CLEAR_BUSINESS_DATA";
export const CLEAR_FUNNEL_DATA = "CLEAR_FUNNEL_DATA";

