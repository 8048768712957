import { UPDATE_FUNNEL_DATA, CLEAR_FUNNEL_DATA } from "../actions";

export const funnelDataReducer = (state = [], action) => {
  let newState = [...state];
  switch (action.type) {
    case UPDATE_FUNNEL_DATA: {
      newState = action.payload.funnel;
      break;
    }
    case CLEAR_FUNNEL_DATA: {
      newState = [];
      break;
    }
  }
  return newState;
};
