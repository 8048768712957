import React from "react";
import { store } from "../redux/store";
import { clearUserData } from "../redux/actions/userData";
import * as _deepClone from "clone-deep";
import * as dashify from "dashify";
import { resetWizardData } from "../redux/actions/funnelWizardData";
import { fileSizeLimits } from "../config";
import Loader from "react-loader-spinner";
import * as moment from "moment";

export const logout = (navRef) => {
  store.dispatch(clearUserData());
  store.dispatch(resetWizardData());
  if (navRef) {
    navRef.replace("/login");
  }
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const slugify = (name) => {
  return dashify(name, { condense: true });
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const openUrlOnNewTab = (url) => {
  window.open(url, "_blank");
};

/**
 *
 * @param {*} size: In KB
 * @param {*} type: 'video' / 'image
 */
export const fileSizeAllowed = (size, type) => {
  if (size > fileSizeLimits[type]) {
    return false;
  } else {
    return true;
  }
};

export const checkIfSessionExpired = (httpError) => {
  console.log(JSON.stringify(httpError));

  if (httpError) {
    logout();
  }
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

const TOAST_COLORS = {
  success: "#28a745",
  error: "#dc3545",
};
export const showToast = (message, type = "error") => {
  const toast = document.createElement("div");
  toast.textContent = message;
  toast.style = `background: ${TOAST_COLORS[type]}; color: white; padding: 11px 12px 10px; font-size: 14px; border-radius: 8px; position: fixed; bottom: 30px; right: 20px; z-index: 1000;`;
  toast.id = `toast-${Math.random()}`.replace(".", "");
  document.body.appendChild(toast);
  return toast.id;
};

export const removeToast = (id) => {
  console.log("removeToast id >>", id);
  const child = document.getElementById(id);
  document.body.removeChild(child);
};

export const justToast = async (message, type = "error", duration = 2000) => {
  const id = showToast(message, type);
  console.log("showToast id", id);
  await new Promise((resolve) => setTimeout(resolve, duration));
  removeToast(id);
};

export const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const birthDate = () => {
  return moment().subtract(18, "years").format("yyyy-MM-DD");
};

export const anniversaryDate = () => {
  return moment().format("yyyy-MM-DD");
};

export const formatDateForDob = (date) => {
  return moment(date).format("yyyy-MM-DD");
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", "-", match[3], "-", match[4]].join(
      ""
    );
  }
  return null;
};

export const loader = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 9999999,
        opacity: 0.95,
        backgroundColor: "white",
      }}
    >
      <div className="loaderWrap">
        <Loader type="Oval" color="#34b277" />
      </div>
    </div>
  );
};

export const formatDateForDashboard = (date) => {
  return moment(date).format("MMM DD,LT");
};

export const getDateRangeValue = (value = "month") => {
  let dateRangeValue = null;

  const currentDate = moment().endOf("day")._d;

  if (value) {
    const startOfDate = moment().startOf(value)._d;
    dateRangeValue = [startOfDate, currentDate];
  }

  return dateRangeValue;
};

export const formatOnlyDateMoment = (value) => {
  return value && moment(value).format("DD/MM/YYYY");
};

export const formatDateMomentForDisable = () => {
  return moment().format("YYYY-MM-DD");
};
