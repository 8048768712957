import React, { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assests/styles/main.css";
import "./assests/styles/custom.css";
import "./assests/styles/react.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import FullPageLoader from "./containers/fullpageloader";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import ProtectedRoute from "./containers/protectedRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assests/styles/SCSS/_index.scss";
import { loader } from "./helper-methods";

const LoginPage = lazy(() => import("./pages/login"));
const SignUpPage = lazy(() => import("./pages/signup"));
const LicenceSignUpPage = lazy(() => import("./pages/registration-licence"));
const ForgotPasswordPage = lazy(() => import("./pages/forgot-password"));
const UserPage = lazy(() => import("./pages/user-page"));
const KioskPage = lazy(() => import("./pages/kiosk-page"));

function App() {
  // console.log=()=>{};
  return (
    <Provider store={store}>
      <PersistGate loading={<div></div>} persistor={persistor}>
        <Router>
          <div>
            <FullPageLoader></FullPageLoader>
            <ToastsContainer
              store={ToastsStore}
              position={ToastsContainerPosition.BOTTOM_RIGHT}
            />
            <Suspense fallback={loader()}>
              <Switch>
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPasswordPage}
                />
                <Route exact path="/register" component={SignUpPage} />
                <Route exact path="/license" component={LicenceSignUpPage} />
                <Route exact path="/login" component={LoginPage} />
                <ProtectedRoute exact path="/kiosk" component={KioskPage} />
                <ProtectedRoute exact path="*" component={UserPage} />
              </Switch>
            </Suspense>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
