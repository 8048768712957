import { UPDATE_BUSINESS_DATA, CLEAR_BUSINESS_DATA } from "../actions";

export const businessDataReducer = (state = [], action) => {
  let newState = [...state];
  switch (action.type) {
    case UPDATE_BUSINESS_DATA: {
      newState = action.payload.business;
      break;
    }
    case CLEAR_BUSINESS_DATA: {
      newState = [];
      break;
    }
  }
  
  return newState;
};
